import React from 'react';
import Link from '../../ui/components/Link';

export default {
    headline: (
        <b>Du hast dich erfolgreich vom Apotique-Newsletter abgemeldet!</b>
    ),
    paragraph: (
        <>
            Wenn Du erneut Benachrichtigungen der Apotique über die neuesten
            Trainings und Aktionen erhalten möchtest, kannst Du dich jederzeit
            unter{' '}
            <Link href="datenschutz-info@pohl-boskamp.de">"Deine Daten"</Link>{' '}
            wieder anmelden.
        </>
    ),
};
