import React from 'react';
import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import lang from '../lang/pages/nl-deaktivieren';

const NewsletterDeActivieren = () => {
    return (
        <Layout>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h3">{lang.headline}</Headline>
                    <Paragraph>{lang.paragraph}</Paragraph>
                </Container>
            </Section>
        </Layout>
    );
};

export default NewsletterDeActivieren;
